import objectPath from "object-path";
import React, { useEffect, useMemo, useState } from "react";
import { useHtmlClassService } from "../../_metronic/layout";
import ApexCharts from "apexcharts";
import axios from "axios";
import { Button } from "react-bootstrap";

export function DashboardPage() {
  const [puissance, setPuissance] = useState(0);
  const [courant, setCourant] = useState(0);
  const [voltage, setVoltage] = useState(0);
  const [energie, setEnergie] = useState(0);
  const [button, setButton] = useState(false);

  const toogleButton = async (e) =>{
    await fetch(`https://ecowico.pythonanywhere.com/onoff/${button?0:1}`);
    setButton(!button)
  }
  const chartColor = "success";
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${chartColor}`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${chartColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, chartColor]);
  const api = "https://testiot.pythonanywhere.com/getvalues";

  const loadData = async () => {
    try {
      const res = await fetch(api);
      const data = await res.json();
      const dataObj = data.reduce((a, v) => ({ ...a, [v.name]: v.value }), {});
      console.log(dataObj);
      setCourant(dataObj.sensor1);
      setPuissance(dataObj.sensor2);
      setEnergie(dataObj.sensor3);
      setVoltage(dataObj.sensor4);
      /*
      chart.appendData([
        {
          data: [[new Date().getTime(), parseFloat(dataObj.sensor2)]],
        },
        {
          data: [[new Date().getTime(), parseFloat(dataObj.sensor4)]],
        },
        {
          data: [[new Date().getTime(), parseFloat(dataObj.sensor1)]],
        },
        {
          data: [[new Date().getTime(), parseFloat(dataObj.sensor3)]],
        },
      ]);
      */

      chartP.appendData([
        {
          data: [[new Date().getTime(), parseFloat(dataObj.sensor2)]],
        },
      ]);
      chartV.appendData([
        {
          data: [[new Date().getTime(), parseFloat(dataObj.sensor4)]],
        },
      ]);
      chartC.appendData([
        {
          data: [[new Date().getTime(), parseFloat(dataObj.sensor1)]],
        },
      ]);
      chartE.appendData([
        {
          data: [[new Date().getTime(), parseFloat(dataObj.sensor3)]],
        },
      ]);
      //chartP.updateSeries([5]);
    } catch (e) {
      console.log(e);
    }
  };

  let chart, chartP, chartV, chartC, chartE;
  useEffect(() => {
    //const element = document.getElementById("energi_chart");

    const puissance = document.getElementById("puissance_chart");
    const voltage = document.getElementById("voltage_chart");
    const courant = document.getElementById("courant_chart");
    const energy = document.getElementById("energie_chart");
    /*
    if (!element) {
      return;
    }
    */

    //const options = getChartOption(layoutProps);

    const optionsP = getChartTime({ title: "Puissance", color: "" });
    const optionsV = getChartTime({ title: "Volatge", color: "" });
    const optionsC = getChartTime({ title: "Courant", color: "" });
    const optionsE = getChartTime({ title: "Energie", color: "" });

    // chart = new ApexCharts(element, options);

    chartP = new ApexCharts(puissance, optionsP);
    chartV = new ApexCharts(voltage, optionsV);
    chartC = new ApexCharts(courant, optionsC);
    chartE = new ApexCharts(energy, optionsE);

    // chart.render();

    chartP.render();
    chartV.render();
    chartC.render();
    chartE.render();

    loadData();
    setInterval(loadData, 2000);
    return function cleanUp() {
      //chart.destroy();

      chartP.destroy();
      chartV.destroy();
      chartC.destroy();
      chartE.destroy();
    };
  }, [layoutProps]);
  return (
    <>
      {" "}
      <div className="row">
        <div className="col-xl-12">
          <div className={`card card-custom gutter-b card-stretch`}>
          
              <Button onClick={toogleButton} variant={button?"success":"danger"}>{button?"ON":"OFF"}</Button>
            
          </div>
        </div>
        <div className="col-xl-6">
   
          <div className={`card card-custom gutter-b card-stretch`}>
            <div className="card-body d-flex flex-column px-8 py-6 mr-8">
              <div className="font-size-h3 text-muted font-weight-bold">
                Puissance
              </div>
              <div className="font-size-h2 font-weight-bolder">
                {puissance} W
              </div>
              <div
                ta-color={chartColor}
                st
                id="puissance_chart"
                dayle={{ height: "150px" }}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className={`card card-custom gutter-b card-stretch`}>
            <div className="card-body d-flex flex-column px-8 py-6 mr-8">
              <div className="font-size-h3 text-muted font-weight-bold">
                Courant
              </div>
              <div className="font-size-h2 font-weight-bolder">{courant} A</div>
              <div
                ta-color={chartColor}
                st
                id="courant_chart"
                dayle={{ height: "150px" }}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className={`card card-custom gutter-b card-stretch`}>
            <div className="card-body d-flex flex-column px-8 py-6 mr-8">
              <div className="font-size-h3 text-muted font-weight-bold">
                Voltage
              </div>
              <div className="font-size-h2 font-weight-bolder">
                {" "}
                {voltage} V
              </div>
              <div
                ta-color={chartColor}
                st
                id="voltage_chart"
                dayle={{ height: "150px" }}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className={`card card-custom gutter-b card-stretch`}>
            <div className="card-body d-flex flex-column px-8 py-6 mr-8">
              <div className="font-size-h3 text-muted font-weight-bold">
                Energie
              </div>
              <div className="font-size-h2 font-weight-bolder">
                {energie} kWh
              </div>
              <div
                ta-color={chartColor}
                st
                id="energie_chart"
                dayle={{ height: "150px" }}
              />
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

function getChartOption(layoutProps) {
  const options = {
    series: [
      {
        name: "puissance",
        data: [],
      },
      {
        name: "voltage",
        data: [],
      },
      {
        name: "courant",
        data: [],
      },
      {
        name: "energie",
        data: [],
      },
    ],
    chart: {
      type: "area",
      height: 500,

      zoom: {
        autoScaleYaxis: true,
      },
    },

    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0.55,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.2,
        stops: [25, 50, 100],
        colorStops: [],
      },
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBaseColor],
    },
    xaxis: {
      type: "datetime",
      min: new Date().getTime(),
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },

    colors: [layoutProps.colorsThemeLightColor],
    markers: {
      colors: [layoutProps.colorsThemeLightColor],
      strokeColor: [layoutProps.colorsThemeBaseColor],
      strokeWidth: 3,
    },
    padding: {
      top: 0,
      bottom: 0,
    },
  };
  return options;
}

function getChartTime({ title, color }) {
  const options = {
    series: [
      {
        name: title,
        data: [],
      },
    ],
    chart: {
      type: "area",
      height: 300,

      zoom: {
        autoScaleYaxis: true,
      },
    },

    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      min: new Date().getTime(),
      tickAmount: 1,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy hh:mm:ss",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };
  return options;
}

function getChartGauge({ title }) {
  const options = {
    title: {
      text: title,
    },
    noData: {
      text: "Loading...",
    },
    series: [55],
    chart: {
      height: 280,
      type: "radialBar",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },

    colors: ["#20E647"],

    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#333",
          startAngle: -90,
          endAngle: 90,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            fontSize: "30px",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        gradientToColors: ["#87D4F9"],
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "butt",
    },
    labels: ["Progress"],
  };
  return options;
}
